import React, { useState, useEffect } from 'react';
import { HashLink as NavLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBriefcase, faProjectDiagram, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

const sections = [
  { id: 'hero', name: 'Home' },
  { id: 'skills', name: 'Skills' },
  { id: 'projects', name: 'Projects' },
  { id: 'contact', name: 'Contact' },
];

const useScrollSpy = () => {
  const [currentSection, setCurrentSection] = useState(null);
  const [shrinkHeader, setShrinkHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 200; // Adjust the offset as needed
      const sectionsOffsets = sections.map((section) => {
        const element = document.getElementById(section.id);
        if (element) {
          return {
            id: section.id,
            offset: element.offsetTop,
          };
        } else {
          return null;
        }
      }).filter(Boolean);

      const closestSection = sectionsOffsets.reduce((closest, current) => {
        return Math.abs(current.offset - scrollPosition) < Math.abs(closest.offset - scrollPosition) ? current : closest;
      });

      setCurrentSection(closestSection.id);

      // Handle header shrinking
      if (window.scrollY > 100) {
        setShrinkHeader(true);
      } else {
        setShrinkHeader(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { currentSection, shrinkHeader };
};

const Header = () => {
  const { currentSection, shrinkHeader } = useScrollSpy();

  return (
    <header className={`header ${shrinkHeader ? 'shrink' : ''}`}>
      <nav className="nav">
        {sections.map((section) => (
          <NavLink
            key={section.id}
            smooth
            to={`#${section.id}`}
            className={`nav-link ${currentSection === section.id ? 'active' : ''}`}
          >
            {section.name}
            {currentSection === section.id && (
              <FontAwesomeIcon
                icon={section.id === 'hero' ? faHome : section.id === 'skills' ? faBriefcase : section.id === 'projects' ? faProjectDiagram : faAddressCard}
              />
            )}
          </NavLink>
        ))}
      </nav>
    </header>
  );
};

export default Header;
