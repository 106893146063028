import React from 'react';
import './Projects.css';

const projects = [
  {
    title: 'Tic-Tac-Toe Game',
    description: 'A classic Tic-Tac-Toe game built with React.',
    technologies: 'React, JavaScript, CSS',
    link: 'https://github.com/Neorex80/Tic-Tac-Toe',
    image: 'xo.jpg', // Add image path
  },
  {
    title: 'Snake Game',
    description: 'An interactive Snake game built with JavaScript.',
    technologies: 'React, JavaScript, CSS',
    link: 'https://github.com/Neorex80/Snake-Game',
    image: 'sg.png', // Add image path
  },
  // Add more projects as needed
];


const Projects = () => {
  return (
    <section id="projects" className="projects-section">
      <h2 className="section-title">Projects</h2>
      <div className="projects-container">
        {projects.map((project, index) => (
          <div className="card" key={index}>
            <div className="content">
              <div className="front">
                <div className="front-content">
                  <small className="badge">{project.technologies}</small>
                  <div className="description">
                    <div className="title">
                      <p><strong>{project.title}</strong></p>
                    </div>
                    <p className="card-footer">{project.description}</p>
                  </div>
                </div>
              </div>
              <div className="back">
                <div className="back-content">
                  <img src={project.image} alt={`Screenshot of ${project.title}`} className="project-image" />
                  <strong>{project.title}</strong>
                  <a href={project.link} target="_blank" rel="noopener noreferrer">
                    View Project
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};


export default Projects;
