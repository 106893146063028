import React from 'react';
import './Skills.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faCss3Alt, faJsSquare, faReact, faNodeJs } from '@fortawesome/free-brands-svg-icons';

const skills = [
  {
    name: 'HTML5',
    icon: faHtml5,
    description: 'Experienced in building structured and semantic web content.',
  },
  {
    name: 'CSS3',
    icon: faCss3Alt,
    description: 'Proficient in responsive design and animations using CSS3.',
  },
  {
    name: 'JavaScript',
    icon: faJsSquare,
    description: 'Skilled in modern JavaScript (ES6+) for dynamic web applications.',
  },
  {
    name: 'React',
    icon: faReact,
    description: 'Expert in building component-based UIs with React.',
  },
  {
    name: 'Node.js',
    icon: faNodeJs,
    description: 'Experienced in server-side development with Node.js.',
  },
  // Add more skills as needed
];

const Skills = () => {
  return (
    <section id="skills" className="skills-section">
      <h2 className="section-title">My Skills</h2>
      <div className="skills-container">
        {skills.map((skill, index) => (
          <div className="skill-card" key={index}>
            <FontAwesomeIcon icon={skill.icon} className="skill-icon" />
            <h3 className="skill-name">{skill.name}</h3>
            <p className="skill-description">{skill.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
